.collapse {
    display: flex;
    flex-direction: column;
    margin: 2% 0;
    flex-basis: 50%;
}

.collapse-banner {
    background: #FF6060;
    display: flex;
    justify-content: space-between;
    padding: 0.5% 2%;
    border-radius: calc(0.1rem + 0.5vw);
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.collapse-title {
    color: white;
    font-size: clamp(1rem, 3vw, 2.5rem);
}

.collapse-chevron {
    width: clamp(1rem, 3vw, 2.5rem);
}

.collapse-chevron.collapsed {
    transform: rotateX(180deg);
}
.collapse-content{
    position: relative;
    top: -1vw;
    background-color: #F6F6F6;
    padding: 7% 5% ;
    border-bottom-left-radius: calc(0.1rem + 0.5vw);
    border-bottom-right-radius: calc(0.1rem + 0.5vw);
    font-size: clamp(1rem, 3vw, 2.5rem);
    display: flex;
    flex-grow: 1;
}
.collapse-description.collapsed {
    display: none;
}