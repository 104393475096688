.tag-ul{
    display: flex;
}
.tag-ul li {
    text-align: center;
    background-color: #FF6060;
    color: white;
    padding: 1% 3%;
    border-radius: clamp(0.4rem, 0.5vw, 0.8rem);
    margin-right: 2%;
    font-size: clamp(0.6rem, 1.5vw, 1.5rem);
}