.author-card{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.author-card-container img {
    width: clamp(3rem, 5vw, 6rem);
    border-radius: 50%;
    object-fit: cover;
}
.author-card-container figcaption{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 10%;
    font-size: clamp(1rem, 3vw, 2rem);
}