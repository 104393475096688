footer{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 20rem;
    padding: 5rem 0;
}
.kasa-logo-white{
    width: clamp(8rem, 14vw, 17rem);
}
.copyright {
    color: white;
    font-size: clamp(1.2rem, 2vw, 2.5rem);
    margin-top: 2rem;
}