.carrousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: calc(0.5em + 1vw);
    object-fit: cover;
}

.chevron-prev {
    position: absolute;
    top: 50%;
    left: 2%;
    z-index: 1;
    transform: translateY(-50%);
    width: clamp(1.2rem, 3vw, 5rem);
    cursor: pointer;
}

.chevron-next {
    position: absolute;
    top: 50%;
    right: 2%;
    z-index: 1;
    transform: translateY(-50%) rotate(180deg);
    width: clamp(1.2rem, 3vw, 5rem);
    cursor: pointer;
}
.counter{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: clamp(1rem, 2vw, 2.5rem);
}