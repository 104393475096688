.home-main {
    margin: 2vw auto;
}

.home-banner-container {
    width: 100%;
    padding-bottom: 30%;
    position: relative;
}

.home-banner-container img {
    filter: brightness(75%)
}

.banner-title {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 5vw;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

.banner-title span {
    color: white;
}

.gallery ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}


@media (min-width: 768px) {
    .home-main {
        margin: 3vw auto;
    }

    .home-banner-container {
        padding-bottom: 25%;
    }

    .banner-title {
        left: 50%;
        display: initial;
        font-size: 3vw;
    }

    .gallery {
        background-color: #F6F6F6;
        border-radius: calc(0.5rem + 1vw);
    }

    .gallery ul {
        grid-template-columns: 1fr 1fr;
        gap: 3vw;
        padding: 5%;
    }

    .card-title-container h2 {
        font-size: 2vw;
    }
}

@media (min-width: 1024px) {
    .home-main{
        margin: 4rem auto;
    }
    .home-banner-container {
        padding-bottom: 20%;
    }
    .banner-title{
        font-size: clamp(3rem, 3vw, 4rem);
    }

    .gallery ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: clamp(2rem, 3vw, 5rem);
        padding: 5%;
    }

    .card {
        border-radius: calc(0.1rem + 0.5vw);
    }

    .card-image-container img {
        border-top-left-radius: calc(0.1rem + 0.5vw);
        border-top-right-radius: calc(0.1rem + 0.5vw);

    }

    .card-title-container {
        border-bottom-left-radius: calc(0.1rem + 0.5vw);
        border-bottom-right-radius: calc(0.1rem + 0.5vw);
    }

    .card-title-container h2 {
        font-size: clamp(1rem, 1.5vw, 1.8rem);
    }
}